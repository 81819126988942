
::v-deep.pages-center{
  padding: 30px 0;
}
.taskList{
  display: flex;
  flex-direction: column;
  height: 100%;
  .task_class{
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #333333;
    div{
      display: flex;
      text-align: center;
      p{
        margin-left: 33px;
      }
      span{
        margin-top: 8px;
        line-height: 32px;
        display: inline-block;
        height: 32px;
        padding: 0 20px;
      }
    }
  }
}
.activeClass {
  background: #4d5aff;
  color: #ffffff;
}
.taskDetail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .studentNameContent{
    color: #999999;
    font-size: 12px;
  }
  span{
    line-height: 20px;
  }
  ::v-deepp{
    line-height:1;
  }
}
.table-box{
  height: calc(100vh - 400px);
  overflow-y: auto;
}
