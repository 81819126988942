
.addTask {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .addTask-header {
    display: flex;

    span {
      display: inline-block;
      padding: 9px 12px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
      border-radius: 2px;
    }

    .activeClass {
      background: #1E33E4;
      color: #fff;
    }
  }

  .form-box {
    margin-top: 40px;
  }

  .announce {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .announce-btn {
      padding: 12px 0;
      width: 120px;

      &:nth-child(1) {
        color: #fff;
        background: #4D5AFF;
        border-color: #4D5AFF;

        &:focus,
        &:hover {
          color: #fff;
          border-color: #463bdc;
          background-color: #463bdc;
        }
      }
    }
  }
}

#article_content {
  z-index: 1;
  width: 50%;
  margin-left: 77px;
}

.imggeshi {
  position: absolute;
  left: 8px;
  top: 24px;
  color: #999999;
  font-size: 12px;
}

.upload-img {
  display: flex;

  .upload-btn {
    width: 148px;
    height: 148px;
    border: 1px dashed #c0ccda;
    box-sizing: border-box;
    background-color: #fbfdff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1d33e3;
    cursor: pointer;

    .addimg {
      width: 22px;
      height: 22px;
      background: #1d33e3;
      color: #ffffff;
      border-radius: 50%;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }

  .cover-look {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    position: relative;

    img {
      max-width: 148px;
      max-height: 158px;
    }

    span {
      position: absolute;
      right: -10px;
      top: 0px;
      cursor: pointer;
    }
  }
}

.file-look {
  display: flex;
  padding-left: 70px;

  .look-item {
    position: relative;

    span {
      position: absolute;
      right: -12px;
      bottom: 8px;
      cursor: pointer;
    }
  }
}

::v-deep .taskObject .el-form-item__label {
  &:before {
    content: '*';
    color: rgb(245, 108, 108);
    margin-right: 4px;
  }
}
