
.taskIndex{
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  .taskIndexHeader{
    margin:0;
    text-align: center;
    width: 100%;
    display: flex;
    p{
      color: #666666;
      font-size: 16px;
      width: 162px;
      line-height: 53px;
      border-radius: 6px 6px 0 0;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  .taskIndex-content{
    display: flex;
    flex-direction: column;
    height: 1%;
    flex: 1;
    background: #fff;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
